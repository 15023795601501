import './Authentications.scss'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Dashboard from '../Modules/Dashboard/dashboard';
import SideNav from '../Shared/SideNav/side_nav';
import TopNav from '../Shared/TopNav/top_nav';
import Settings from '../Modules/Settings/settings';
import WeddingHalls from '../Main-Modules/wedding-halls/wedding-halls';
import Studios from '../Main-Modules/studios/studios';
import Catering from '../Main-Modules/catering/catering';
import Decoration from '../Main-Modules/decoration/decoration';
import Purohit from '../Main-Modules/purohit/purohit';
import BandSet from '../Main-Modules/band-set/band-set';
import CarRentals from '../Main-Modules/car-rentals/car-rentals';
import DigitalInvitations from '../Modules/digital-invitation/digital-invitaion';
import RajasekharDharani from '../Modules/digital-invitation/rajasekhar-dharani';
import SushmithaInvite from '../Modules/digital-invitation/Sushmith-invite';
import JayachandraInvite from '../Modules/digital-invitation/jayachandra-invite';
import VendorDetailsPage from '../Modules/Dashboard/vendor-detail-page/vendor-detail-page';
import PremChandraInvite from '../Modules/digital-invitation/premchandra-invite';

const Authentications = () => {
  const { REACT_APP_TOKEN_NAME, REACT_APP_a2z_wedding_info_THEME } = process.env;
  const [cookies] = useCookies([REACT_APP_TOKEN_NAME]);
  const [cookies1, setCookie1] = useCookies([REACT_APP_a2z_wedding_info_THEME]);
  const [cookies2, setCookie2] = useCookies(["toggle"]);
  const [menu, setMenu] = useState(cookies2?.toggle === "true" ? true : false);
  const [darkMode, setDarkMode] = useState(cookies1?.theme === "light_theme" ? false : cookies?.theme === undefined ? false : true)

  // const user = cookies && cookies[REACT_APP_TOKEN_NAME] !== undefined && Object.keys(cookies[REACT_APP_TOKEN_NAME]).length !== 0 ? true : false

  const Toggle = () => {
    if (menu) {
      setCookie2('toggle', false, { path: '/' });
    }
    else {
      setCookie2('toggle', true, { path: '/' });
    }

    let navigation = document.querySelector(".a2z_sidenav");
    let a2z_wedding_info_logo = document.querySelector(".a2z_wedding_info_logo");
    let main = document.querySelector(".a2z_mainContent");

    a2z_wedding_info_logo.classList.toggle("active");
    navigation.classList.toggle("active");
    main.classList.toggle("active");

    setMenu(!menu);
  };

  const HandleDarkMode = (mode) => {

    if (mode) {
      document.body.className = 'dark_theme'
      setCookie1('theme', "dark_theme", { path: '/' });
      setDarkMode(true)
    }
    if (!mode) {
      document.body.className = 'light_theme'
      setCookie1('theme', "light_theme", { path: '/' });
      setDarkMode(false)
    }
  }

  function Redirect({ to }) {

    let navigate = useNavigate();
    useEffect(() => {
      navigate(to);
    });
    return null;
  }

  const handleResize = () => {
    setMenu(window.innerWidth >= 768)
  };

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className="a2w_main_root">
        <div className={menu ? "a2z_sidenav active" : "a2z_sidenav"}>
          <SideNav menu={menu} Toggle={Toggle} darkMode={darkMode} HandleDarkMode={HandleDarkMode} />
        </div>
        <div className={menu ? "a2z_mainContent active" : "a2z_mainContent"}>
          <div className="a2z_header">
            <TopNav Toggle={Toggle} darkMode={darkMode} HandleDarkMode={HandleDarkMode} />
          </div>
          <div className="a2z_switchingContent">

            <Routes>
              <Route path="/*" element={<Redirect to="/dashboard" />} />
              <Route path="/" element={<Redirect to="/dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/veder-details" element={<VendorDetailsPage />} />
              <Route path='/wedding-halls' element={<WeddingHalls />} />
              <Route path='/studios' element={<Studios />} />
              <Route path='/catering' element={<Catering />} />
              <Route path='/decorations' element={<Decoration />} />
              <Route path='/purohit' element={<Purohit />} />
              <Route path='/band-set' element={<BandSet />} />
              <Route path='/car-rentals' element={<CarRentals />} />
              <Route path='/digital-invitations' element={<DigitalInvitations />} />
              <Route path='/rajasekhar-weds-dharani' element={<RajasekharDharani />} />
              <Route path='/Jayasaikrishna-weds-Sushmitha' element={<SushmithaInvite />} />
              <Route path='/Jayachandra-weds-Nagamani' element={<JayachandraInvite />} />
              <Route path='/premchandra-weds-srilatha' element={<PremChandraInvite />} />
              <Route path="/settings/profileInfo" element={<Settings />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}

export default Authentications