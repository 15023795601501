import React from 'react'
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader } from '../../Shared/Card/card';
import { CommonHeader } from '../../Shared/Header/CommonHeader';
import './dashboard.scss';
import { ToastContainer, toast } from 'react-toastify';
import MenuData from '../../shared-data/menuData.json'
import { Icon } from 'semantic-ui-react';

const Dashboard = () => {
  const moveToInvite = (path) => {
    if (path === '') {
      toast.warn('Coming Soon')
    }
    else {
      window.location.replace(path);
    }
  }

  return (
    <div className='m200_container'>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <CommonHeader title={''} />
      <div className='m200_dashboard_container'>
        <div className='m200_left_Container'>
          <Card className="dashboard_grid-item1">
            <CardHeader className={'s400_card_title_blue'} title="Vendor categories"></CardHeader>
            <CardBody>
              <div className="overview_content">
                <div className="gridContainer">
                  {MenuData.map((singleGrid, index) => {
                    return (
                      <div key={index} className="iconGrid">
                        {/* <Link className="iconLink" to={singleGrid.path} > 
                          <img className="icons" alt={singleGrid.name} src={singleGrid.iconURL} />
                          <div className="iconText">{singleGrid.name}</div> 
                        </Link> */}
                        <Link className='vender-card' to={singleGrid.path} >
                          {singleGrid?.venders_count && <span className='vender-count'> {singleGrid?.venders_count}</span>}
                          <img className='vender-image'  alt={singleGrid.name} src={singleGrid?.iconURL} />
                          <div >
                            <div className='vender-title'>{singleGrid?.name}</div>
                            <div className='vender-name'>{singleGrid?.top_rated_vendors}</div>
                          </div>
                        </Link>
                      </div>)
                  })}
                </div>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader className={'s400_card_title_blue'} title="Wedding invitations"></CardHeader>

            <CardBody>
              <div className= "overview_content">
                <div className="overview_card total_normal" onClick={() => moveToInvite('/Jayachandra-weds-Nagamani')}>
                  <div className="overviewTitle">
                    <span className='overview_card_titles'> YERRAGUNTA VARI</span>
                  </div>
                  <img className="overViewCard_avatar" src="/invites_img/jn.PNG" alt="sushmitha a2zwi" />

                  <div className="overviewTitle">
                    <h4 className='overview_card_titles'>Jayachandra Reddy &nbsp;
                      <Icon color='white' disabled name='heart' />
                      Nagamani</h4>
                  </div>
                  <div className="wedding_date">
                    16 / 03 / 2024
                  </div>
                </div>

                <div className="overview_card total_normal" onClick={() => moveToInvite('/JayaSaiKrishna-weds-Sushmitha')}>
                  <div className="overviewTitle">
                    <span className='overview_card_titles'> UMMANNI</span>
                  </div>
                  <img className="overViewCard_avatar" src="/invites_img/js.PNG" alt="sushmitha a2zwi" />

                  <div className="overviewTitle">
                    <h4 className='overview_card_titles'>Jaya SaiKrishna &nbsp;
                      <Icon color='white' disabled name='heart' />
                      Sushmitha</h4>
                  </div>
                  <div className="wedding_date">
                    17 / 12 / 2023
                  </div>
                </div>
                <div className="overview_card total_normal" onClick={() => moveToInvite('https://pabbuleti-wedding-invitation.netlify.app/')}>
                  <div className="overviewTitle">
                    <span className='overview_card_titles'>PABBULETI</span>
                  </div>
                  <img className="overViewCard_avatar" src="/invites_img/ps.PNG" alt="Swetha a2zwi" />

                  <div className="overviewTitle">
                    <h4 className='overview_card_titles'>Prashant &nbsp;
                      <Icon color='white' disabled name='heart' />
                      Swetha</h4>
                  </div>
                  <div className="wedding_date">
                    14 / 12 / 2023
                  </div>
                </div>
                <div className="overview_card total_normal" >
                  <div className="overviewTitle">
                    <span className='overview_card_titles'>MANDLIPALLI</span>
                  </div>
                  <img className="overViewCard_avatar" src="/invites_img/rd.PNG" alt="Dharani a2zwi" />

                  <div className="overviewTitle">
                    <h4 className='overview_card_titles'>Rajasekhar reddy &nbsp;
                      <Icon color='white' disabled name='heart' />
                      Dharani</h4>
                  </div>
                  <div className="wedding_date">
                    01 / 11 / 2023
                  </div>
                </div>
                <div className="overview_card total_normal">
                  <div className="overviewTitle">
                    <span className='overview_card_titles'>BOYAPATI</span>
                  </div>
                  <img className="overViewCard_avatar" src="/invites_img/vp.jpg" alt="Venkatesh a2zwi" />
                  <div className="overviewTitle">
                    <h4 className='overview_card_titles'>Venkatesh &nbsp; <Icon color='white' disabled name='heart' /> Pravalika</h4>
                  </div>
                  <div className="wedding_date">
                    10 / 02 / 2022
                  </div>
                </div>
                <div className="overview_card total_normal" onClick={() => moveToInvite('https://swashakha.netlify.app')}>
                  <div className="overviewTitle">
                    <span className='overview_card_titles'>SWASHAKHA</span>
                  </div>
                  <img className="overViewCard_avatar" src="/invites_img/kv.jpeg" alt="karthik a2zwi" />
                  <div className="overviewTitle">
                    <h4 className='overview_card_titles'>Karthik &nbsp; <Icon color='white' disabled name='heart' /> Vaishnavi</h4>
                  </div>
                  <div className="wedding_date">
                    10 / 11 / 2021
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          {/* <a href='tel:+91 9966767255'>

          <img width={50} height={50} alt='call' src='https://media.tenor.com/zVNzIFTdYIEAAAAi/call.gif' />
          </a>

          <a href='https://api.whatsapp.com/send?phone=919966767255'>

          <img width={50} height={50} alt='call' src='https://static.vecteezy.com/system/resources/previews/016/716/480/original/whatsapp-icon-free-png.png' />
          </a> */}
        </div>
      </div>
    </div>
  )
}

export default Dashboard